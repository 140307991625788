var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "수질-자가측정 결과 업로드" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLAPPLY", icon: "save" },
                        on: { btnClicked: _vm.acceptData },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-plant", {
                  attrs: { editable: false, type: "edit", name: "plantCd" },
                  model: {
                    value: _vm.data.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "plantCd", $$v)
                    },
                    expression: "data.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-field", {
                  attrs: {
                    editable: false,
                    data: _vm.data,
                    deptValue: "deptCd",
                    type: "dept_user",
                    label: "작성부서 & 작성자",
                    name: "userId",
                  },
                  model: {
                    value: _vm.data.userId,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "userId", $$v)
                    },
                    expression: "data.userId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    editable: false,
                    label: "측정일",
                    name: "measureDt",
                  },
                  model: {
                    value: _vm.data.measureDt,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "measureDt", $$v)
                    },
                    expression: "data.measureDt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-select", {
                  attrs: {
                    type: "edit",
                    editable: false,
                    codeGroupCd: "WEATHER_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "날씨",
                    name: "weatherCd",
                  },
                  model: {
                    value: _vm.data.weatherCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "weatherCd", $$v)
                    },
                    expression: "data.weatherCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: false,
                    label: "온도",
                    suffix: "℃",
                    name: "temperature",
                  },
                  model: {
                    value: _vm.data.temperature,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "temperature", $$v)
                    },
                    expression: "data.temperature",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-select", {
                  attrs: {
                    type: "edit",
                    editable: false,
                    codeGroupCd: "WATER_MEASURE_TYPE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "측정구분",
                    name: "waterMeasureTypeCd",
                  },
                  model: {
                    value: _vm.data.waterMeasureTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "waterMeasureTypeCd", $$v)
                    },
                    expression: "data.waterMeasureTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: false,
                    label: "환경기술인",
                    name: "envUserName",
                  },
                  model: {
                    value: _vm.data.envUserName,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "envUserName", $$v)
                    },
                    expression: "data.envUserName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-text", {
                  attrs: {
                    editable: false,
                    label: "환경기술인 의견",
                    name: "envRemark",
                  },
                  model: {
                    value: _vm.data.envRemark,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "envRemark", $$v)
                    },
                    expression: "data.envRemark",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-excel-upload", {
        attrs: { editable: _vm.editable, excelUploadInfo: _vm.excelUploadInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }